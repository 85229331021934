<template>
    <projects-component/>
</template>

<script>
    import ProjectsComponent from "@/components/projects/ProjectsComponent";
    
    export default {
        name: "Projects",
        title: "Proyectos | Private",
        components: {ProjectsComponent}
    }
</script>

<style scoped>

</style>